import React, { useState } from 'react';
import { Auth as Amplify } from 'aws-amplify';
import api from '../utils/api';
import { Auth } from '../types/Auth';
import Loading from '../components/Loading';
import { User } from '../types/User';
import { ResponseProps } from '../services/response.props';

interface Props {
  children: React.ReactNode;
}

interface Request {
  data?: Auth;
  loading?: boolean;
  isAuthorized?: boolean;
}

interface State {
  auth: Auth;
  user?: User;
  eventWizard?: boolean;
  isDrawerOpen: boolean;
  login: (username: string) => void;
  setEventWizard: (show: boolean) => void;
  setCustomer: (hasCustomer: boolean) => void;
  logout: () => void;
  toggleDrawer: () => void;
}

const AuthContext = React.createContext<State>(null);

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [auth, setAuth] = React.useState<Request>({
    data: undefined,
    loading: true,
    isAuthorized: false,
  });

  const [showEventWizard, setShowEventWizard] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        const session = await Amplify.currentAuthenticatedUser();
        const token = session.signInUserSession.idToken.jwtToken;

        api.defaults.headers.Authorization = `Bearer ${token}`;

        const response = await api.get<ResponseProps<User>>('users/auth');
        const { body } = response.data;

        setAuth({
          loading: false,
          data: {
            user: body.data,
          },
        });
      } catch (error) {
        setAuth({
          loading: false,
          data: undefined,
        });
      }
    })();
  }, [auth.isAuthorized]);

  function toggleDrawer() {
    setIsDrawerOpen((prevState) => !prevState);
  }

  function setEventWizard(show: boolean) {
    setShowEventWizard(show);
  }

  function setCustomer(hasCustomer: boolean = true) {
    setAuth({
      ...auth,
      data: {
        ...auth.data,
        user: {
          ...auth.data.user,
          hasCustomer,
        },
      },
    });
  }

  function login(username: string) {
    setAuth({
      isAuthorized: true,
      loading: true,
    });
  }

  function logout() {
    setAuth({
      data: undefined,
      loading: false,
    });

    api.defaults.headers.Authorization = null;

    localStorage.removeItem('@babySlug');

    Amplify.signOut();
  }

  if (auth.loading) {
    return (
      <div style={{ height: '100vh' }}>
        <div style={{ paddingTop: '45vh' }}>
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        auth: auth.data,
        user: auth.data?.user,
        eventWizard: showEventWizard,
        isDrawerOpen,
        login,
        setEventWizard,
        setCustomer,
        logout,
        toggleDrawer,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
